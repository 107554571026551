import React from 'react'

import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { MatchContext } from '../../providers/match'

function Single() {
  const { t, i18n, roomSize, typeRoom, matchConfig } =
    React.useContext(MatchContext);
  function getTextFooter() {
    const lang = i18n.language;
    let textFooter = '';
    if (lang === 'ptBr') {
      textFooter = matchConfig?.footerTextBr;
    }
    if (lang === 'en') {
      textFooter = matchConfig?.footerTextEn;
    }
    if (lang === 'sp') {
      textFooter = matchConfig?.footerTextSp;
    }

    return textFooter;
  }
  return (
    <Card className="room-card-main">
      <CardBody>
        <CardTitle className="room-card-title">
          {`${t('Tipo de quarto')}: ${typeRoom}`}
          <hr />
        </CardTitle>
        <div className="room-container-items">
          <p>{t('Sua hospedagem está confirmada em apartamento Single!')}</p>
          <i className="mdi mdi-check-circle-outline room-icon-success" />
        </div>
      </CardBody>
      {getTextFooter() && (
        <CardFooter className="footer-text">
          <Row>
            <Col>
              <p>{getTextFooter()}</p>
            </Col>
          </Row>
        </CardFooter>
      )}
    </Card>
  );
}
export default Single