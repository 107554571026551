import React from "react";
import { Badge, Button, Card, CardBody } from "reactstrap";
import { MatchContext } from "../providers/match";
import { Ienums } from "../utils/interface";
import { useNavigate } from "react-router-dom";
import { getMatchConfig } from '../utils/services/matchConfig';

function CardRoomItem(props) {
  const navigate = useNavigate();

  async function getConfigError() {
    const params = new URLSearchParams(window.location.search);
    const res = await getMatchConfig(
      params.get('idEvent'),
      params.get('idPart')
    );
    if (res?.message === 'MATCH_CONFIG_FOUND') {
      return false;
    }
    if (
      res?.message === 'MATCH_CONFIG_NOT_FOUND' ||
      res?.message === 'EXISTS_NO_MATCH_GROUPS'
    ) {
      return true;
    }
  }
  const { t } = React.useContext(MatchContext);
  return (
    <div className="div-card-roomItem">
      <Card className="card-room-item-main" style={{ width: '100%' }}>
        <CardBody>
          {props.status !== 'N' ? (
            <div className="card-room-item-body">
              <p onClick={() => console.log(props)}>{props.name}</p>
              <div className="card-room-item-badge-container">
                {props.status === Ienums.PENDING_ACCEPT && (
                  <Badge color="warning">{t('Pendente aceite')}</Badge>
                )}
                {props.status === Ienums.PENDING_SEND && (
                  <Badge color="warning">
                    {t('Pendente Envio da Solicitação')}
                  </Badge>
                )}
                {props.status === Ienums.COMPLETED && (
                  <Badge color="success">{t('Concluído')}</Badge>
                )}
              </div>
            </div>
          ) : (
            <div className="card-room-item-button">
              <Button
                onClick={async () => {
                  const errorConfig = await getConfigError();
                  if (errorConfig) {
                    const searchParams = window.location.search;
                    window.location.href = '/select' + searchParams;
                  } else {
                    navigate({
                      pathname: '/participants',
                      search: window.location.search,
                    });
                  }
                }}
                color="warning"
              >
                +
              </Button>
              <span>{t(props.name)}</span>
            </div>
          )}
        </CardBody>
      </Card>
      {props.status === Ienums.PENDING_SEND && (
        <div
          className="div-icon-delete"
          onClick={() => props.confirmDeleteUser(props.allData)}
        >
          <i className="bx bx-trash-alt" />
        </div>
      )}
    </div>
  );
}
export default CardRoomItem;
