import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParticipants } from "../../hooks/useParticipants";
import { getMatchConfig } from '../../utils/services/matchConfig';

export const MatchContext = React.createContext({});

function MatchProvider(props) {
  const [step, setStep] = useState('_a');
  const [idParticipant, setIdParticipant] = useState();
  const [idEvent, setIdEvent] = useState('');
  const [roomSize, setRoomSize] = useState('');
  const [typeRoom, setTypeRoom] = useState('');
  const [matchConfig, setMatchConfig] = useState('');
  const [errorConfig, setErrorConfig] = useState(false);
  const [errorConfigMsg, setErrorConfigMsg] = useState('');

  const [currentContext, setNewContextRefresh] = useState(Date.now());

  const { t, i18n } = useTranslation();
  const participants = useParticipants();

  useEffect(() => {
    getParamsUrl();
  }, [currentContext]);

  function getParamsUrl() {
    const params = new URLSearchParams(window.location.search);
    setIdParticipant(params.get('idPart'));
    setIdEvent(params.get('idEvent'));
    setIdiom(params.get('idiom'));
    setRoomSize(
      parseInt(params.get('room')) > 4 ? 4 : parseInt(params.get('room'))
    );
    setTypeOfRoom(parseInt(params.get('room')));
    getConfig(params.get('idEvent'), params.get('idPart'));
  }

  async function getConfig(idEvent, idParticipant) {
    const res = await getMatchConfig(idEvent, idParticipant);

    if (res?.message === 'MATCH_CONFIG_FOUND') {
      setMatchConfig(res.data);
    }
    if (
      res?.message === 'MATCH_CONFIG_NOT_FOUND' ||
      res?.message === 'EXISTS_NO_MATCH_GROUPS'
    ) {
      setErrorConfig(true);
      setErrorConfigMsg(res?.message);
    }
  }
  function setIdiom(idiom) {
    const lang =
      idiom === 'pt-br' || idiom === 'ptBr' || idiom?.toLowerCase() === 'pt'
        ? 'ptBr'
        : idiom === 'sp' || idiom === 'es'
        ? 'sp'
        : 'en';
    i18n.changeLanguage(lang);
  }

  function setTypeOfRoom(size) {
    if (size === 1) setTypeRoom('Single');
    if (size === 2) setTypeRoom('Duplo');
    if (size === 3) setTypeRoom('Triplo');
    if (size > 3) setTypeRoom('Quádruplo');
  }

  return (
    <MatchContext.Provider
      value={{
        step,
        idParticipant,
        setStep,
        setIdParticipant,
        setRoomSize,
        setNewContextRefresh,
        setTypeOfRoom,
        t,
        idEvent,
        i18n,
        roomSize,
        participants,
        typeRoom,
        matchConfig,
        errorConfig,
        errorConfigMsg,
      }}
    >
      {props.children}
    </MatchContext.Provider>
  );
}

export default MatchProvider;
