import { useEffect, useState } from "react";

// ele só altera o valor do search depois que a pessoa para de digitar,
// então no searchValue iria o estado do input de pesquisa
// o delay vai receber o tempo de espera antes de fazer a pesquisa
function useDebounce(searchValue, delay) {
  const [debounceValue, setDebounceValue] = useState(searchValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchValue.length > 2 || searchValue === "") {
        setDebounceValue(searchValue);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // enquanto a pessoa não parar de digitar esse useEffect fica ativando
  }, [searchValue, delay]);

  // caso a pessoa pare de digitar pelo tempo passado no delay
  // devolve o valor atual do input search para ser usado no fetch
  return debounceValue;
}

export default useDebounce;
