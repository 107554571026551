import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  CardTitle,
  Row,
  Spinner,
} from "reactstrap";
import { MatchContext, errorConfigMsg } from '../../providers/match';
import CardRoomItem from '../../components/CardRoomItem';
import {
  checkRoom,
  createRoom,
  sendInviteConfirmation,
} from '../../utils/services/roommates';
import { Ienums } from '../../utils/interface';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reset, deleteUser } from '../../store/users';
import CardError from '../../components/CardError';

function BigRoom(props) {
  const navigate = useNavigate();

  const {
    t,
    i18n,
    typeRoom,
    roomSize,
    idParticipant,
    idEvent,
    setNewContextRefresh,
    matchConfig,
    errorConfig,
    errorConfigMsg,
  } = React.useContext(MatchContext);
  const [roommates, setRoommates] = useState([]);
  // const [status, setStatus] = useState(props.status)
  const [roomId, setRoomId] = useState(props.roomId);
  const [errorValidation, setErrorValidation] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [confirmInviteAlert, setConfirmInviteAlert] = useState(false);
  const [isVisibleButton, setIsVisibleButton] = useState(false);
  const [typeConfirmation, setTypeConfirmation] = useState('');
  const [confirmAskAlert, setConfirmAskAlert] = useState('');
  const [availableRoomValidationError, setAvailableRoomValidationError] =
    useState(false);
  const [type, setType] = useState('');
  // deletar usuario selecinado
  const [deleteData, setDeleteData] = useState(null);
  const [confirmDeleteDataAlert, setConfirmDeleteDataAlert] = useState(false);
  const [deleteDataAlert, setDeleteDataAlert] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonConfirmDisabled, setIsButtonConfirmDisabled] = useState(false);
  const [isButtonDenyDisabled, setIsButtonDenyDisabled] = useState(false);

  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);

  function checkVisible() {
    return users.filter((e) => e.status === Ienums.PENDING_SEND).length > 0;
  }
  function checkConfirmVisible() {
    return (
      users.filter(
        (e) => e.status === Ienums.PENDING_ACCEPT && e.id === idParticipant
      ).length > 0 &&
      users.filter((e) => e.id === idParticipant)[0].type !== 'chooser'
    );
  }

  function checkComplete() {
    const chooser = users.filter((e) => e.type === 'chooser');
    const chooserPending = users.filter(
      (e) => e.status === Ienums.PENDING_ACCEPT
    );

    if (
      chooser[0].id === idParticipant &&
      users?.length < roomSize &&
      !chooserPending[0]
    ) {
      return 'escolha';
    }

    if (
      users.filter(
        (e) =>
          e.status === Ienums.PENDING_ACCEPT || e.status === Ienums.PENDING_SEND
      ).length === 0
    ) {
      return 'feito';
    }

    // return (
    //   users.filter(
    //     (e) =>
    //       e.status === Ienums.PENDING_ACCEPT || e.status === Ienums.PENDING_SEND
    //   ).length === 0
    // );
  }

  function confirmInvite() {
    setIsButtonConfirmDisabled(true);
    setIsButtonDenyDisabled(true);
    sendInviteConfirmation({
      eventId: idEvent,
      roommateId: idParticipant,
      type: type,
      idiom: i18n.language,
    }).then((e) => {
      setTypeConfirmation(e.message);
      setConfirmInviteAlert(true);
    });
  }

  function modalConfirm() {
    setConfirmAskAlert(true);
  }

  function getUsersSelected() {
    const size = roomSize - users.length;
    const totalFilled = users.map((e) => (
      <CardRoomItem
        name={e.name}
        status={e.status}
        roomId={roomId}
        allData={e}
        confirmDeleteUser={confirmDeleteUser}
      />
    ));
    const totalToFill = Array(size)
      ?.fill()
      .map((e) => (
        <CardRoomItem
          name={`Adicione seu parceiro(a) de quarto`}
          status="N"
          roomId={roomId}
        />
      ));
    return totalFilled.concat(totalToFill);
  }

  function confirmDeleteUser(e) {
    setDeleteData(e);
    setConfirmDeleteDataAlert(true);
  }

  function deleteUserFunc() {
    setConfirmDeleteDataAlert(false);
    dispatch(deleteUser(deleteData));
    setDeleteDataAlert(true);
  }

  function sendRequest() {
    setIsButtonDisabled(true);
    const data = {
      eventId: idEvent,
      roommateId: idParticipant,
      roomSize: roomSize,
      status: Ienums.PENDING_ACCEPT,
      enabled: true,
      choosens: [users],
      idiom: i18n.language,
    };

    checkRoom({
      eventID: idEvent,
      size: roomSize,
    })
      .then((r) => {
        if (r.full === false) {
          setAvailableRoomValidationError(false);
          createRoom(data).then((res) => {
            if (res.message === Ienums.CHOOSER && res.data.length > 0) {
              setSuccessAlert(true);
            }
            if (res.message === 'ERROR_VALIDATE_CHOOSENS') {
              setErrorValidation(true);
            }
          });
        }
        if (r.full === true) {
          setAvailableRoomValidationError(true);
        }
      })
      .catch((r) => {
        // console.log("response error", r);
      });
  }
  function getTextFooter() {
    const lang = i18n.language;
    let textFooter = '';
    if (lang === 'ptBr') {
      textFooter = matchConfig?.footerTextBr;
    }
    if (lang === 'en') {
      textFooter = matchConfig?.footerTextEn;
    }
    if (lang === 'sp') {
      textFooter = matchConfig?.footerTextSp;
    }

    return textFooter;
  }

  if (errorConfig) {
    const searchParams = window.location.search;
    window.location.href = '/select' + searchParams;
  }

  return (
    <>
      {users?.length > 0 ? (
        <Card className="room-card-main">
          <CardBody className="room-card-body">
            <CardTitle className="room-card-title">
              {`${t('Tipo de quarto')}: ${t(`${typeRoom}`)}`}
              <hr />
            </CardTitle>
            <div className="room-container-items">
              {checkComplete() === 'escolha' && (
                <p>{t('Escolha seu parceiro(a) de quarto')}</p>
              )}

              {checkComplete() === 'feito' && (
                <p>
                  {t('Sua hospedagem está confirmada em apartamento')}{' '}
                  {roomSize === 2
                    ? t('Duplo!')
                    : roomSize === 3
                    ? t('Triplo')
                    : t('Quádruplo')}{' '}
                </p>
              )}
              {/* {!checkComplete() ? (
                <p>{t("Escolha seu parceiro(a) de quarto")}</p>
              ) : (
                <p>
                  {t("Sua hospedagem está confirmada em apartamento")}{" "}
                  {roomSize === 2
                    ? t("Duplo!")
                    : roomSize === 3
                    ? t("Triplo")
                    : t("Quádruplo")}{" "}
                </p>
              )} */}
              {users.length > 0 && getUsersSelected()}
            </div>
            {checkVisible() && (
              <Button
                disabled={isButtonDisabled || users.length < roomSize}
                color="primary"
                onClick={() => {
                  sendRequest();
                }}
              >
                {t('Enviar o convite')}
              </Button>
            )}{' '}
            {checkConfirmVisible() && (
              <div>
                <p style={{ display: 'flex', marginLeft: '5%', width: '90%' }}>
                  {t(
                    'Informe nas opções abaixo se você deseja aceitar ou recusar ficar hospedado(a) com o(a) participante acima'
                  )}
                </p>
                <Button
                  disabled={isButtonConfirmDisabled}
                  color="primary"
                  onClick={() => {
                    setType(Ienums.COMPLETED);
                    modalConfirm();
                  }}
                >
                  {t('Aceitar')}
                </Button>
                <Button
                  disabled={isButtonDenyDisabled}
                  style={{ marginLeft: '10px' }}
                  color="danger"
                  onClick={() => {
                    setType(Ienums.DENIED);
                    modalConfirm();
                  }}
                >
                  {t('Recusar')}
                </Button>
              </div>
            )}
          </CardBody>
          {getTextFooter() && (
            <CardFooter className="footer-text">
              <Row>
                <Col>
                  <p>{getTextFooter()}</p>
                </Col>
              </Row>
            </CardFooter>
          )}
        </Card>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner size="sm" color="light" type="grow" />
        </div>
      )}

      {errorValidation && (
        <SweetAlert
          danger
          title={t(
            'Alguns participantes já foranm escolhidos. Por favor recrie escolhendo outros parceiros'
          )}
          onConfirm={() => {
            dispatch(reset([]));
            setErrorValidation(false);
            navigate({
              pathname: '/match',
              search: window.location.search,
            });
            //window.location.href = '/match' + window.location.search
          }}
        />
      )}

      {availableRoomValidationError && (
        <SweetAlert
          danger
          title={t('Tipo de quarto indisponível.')}
          onConfirm={() => {
            dispatch(reset([]));
            setAvailableRoomValidationError(false);
            navigate({
              pathname: '/select',
              search: `?idEvent=${idEvent}&idPart=${idParticipant}&room=0&idiom=${i18n.language}`,
            });
            //window.location.href = '/select/' + `?idEvent=${idEvent}&idPart=${idParticipant}&room=0&idiom=${i18n.language}`
          }}
        />
      )}

      {successAlert && (
        <SweetAlert
          success
          title={t('Seu convite foi enviado!')}
          onConfirm={() => {
            setSuccessAlert(false);
            //window.location.href = '/match' + window.location.search
            /* navigate({
              pathname: "/match",
              search: window.location.search,
            }); */
            document.location.reload(true);
          }}
        >
          {t('Agora, aguarde a confirmação do seu parceiro(a).')}
        </SweetAlert>
      )}
      {confirmInviteAlert && (
        <SweetAlert
          success
          title={
            type === Ienums.DENIED
              ? t('Solicitação de hospedagem recusada com sucesso')
              : t('Confirmação de hospedagem realizada com sucesso')
          }
          onConfirm={() => {
            setConfirmInviteAlert(false);
            if (typeConfirmation === 'INVITE_DENIED') {
              dispatch(reset([]));
              navigate({
                pathname: '/select',
                search: `?idEvent=${idEvent}&idPart=${idParticipant}&room=0&idiom=${i18n.language}`,
              });
              //window.location.href = '/select/' + `?idEvent=${idEvent}&idPart=${idParticipant}&room=0&idiom=${i18n.language}`
            } else {
              dispatch(reset([]));
              setNewContextRefresh();
              navigate({
                pathname: '/match',
                search: window.location.search,
              });
            }
            document.location.reload(true);
          }}
        />
      )}

      {confirmAskAlert && (
        <SweetAlert
          title={
            type === Ienums.DENIED
              ? t(
                  `Você tem certeza que deseja recusar a solicitação de hospedagem?`
                )
              : t(
                  'Você tem certeza que deseja aceitar a solicitação de hospedagem?'
                )
          }
          warning
          showCancel
          confirmBtnText={t('Sim')}
          cancelBtnText={t('Não')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmInvite();
            setConfirmAskAlert(false);
          }}
          onCancel={() => setConfirmAskAlert(false)}
        />
      )}

      {confirmDeleteDataAlert && (
        <SweetAlert
          title={`${t('Deseja remover')} ${deleteData.name} ${t(
            'da sua lista?'
          )}`}
          warning
          showCancel
          confirmBtnText={t('Sim')}
          cancelBtnText={t('Não')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteUserFunc();
          }}
          onCancel={() => setConfirmDeleteDataAlert(false)}
        />
      )}

      {deleteDataAlert && (
        <SweetAlert
          title={`Removido da lista`}
          success
          onConfirm={() => {
            setDeleteDataAlert();
          }}
        />
      )}
    </>
  );
}
export default BigRoom;
