export const Ienums = {
    ACCEPTED: "accepted",
    CHOOSER: "chooser",
    CHOOSEN: "choosen",
    COMPLETED: "completed",
    CREATE_ROOM: "createroom",
    PENDING_SEND: "pending_send",
    PENDING_ACCEPT: "pending_accept",
    PENDING_SUBMIT: "pending_submit",
    SUBMITTED: "submitted",
    DENIED: "denied"
}
