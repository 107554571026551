import React, { useEffect } from "react";
import SingleRoom from "./singleroom";
import BigRoom from "./bigroom";

import { MatchContext } from "../../providers/match";

function Room() {
  const { roomSize, setNewContextRefresh } = React.useContext(MatchContext);

  useEffect(() => {
    //envia 1 novo estado, pra atualizar o provider do context
    setNewContextRefresh();

    return () => setNewContextRefresh();
  }, []);

  return (
    <div className="room-container-main">
      {roomSize > 1 ? <BigRoom /> : <SingleRoom />}
    </div>
  );
}
export default Room;
