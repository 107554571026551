import React, { useContext, useState, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from 'reactstrap';

function CardError(props) {
  return (
    <div className="container-main" style={{ backgroundColor: 'lightgray' }}>
      <div className="room-container-main">
        <Card className="room-card-main">
          <CardBody className="room-card-body">
            <div className="room-container-items">
              <i className="mdi mdi-alert-circle-outline room-icon-success text-danger" />
              {props?.message === 'MATCH_CONFIG_NOT_FOUND' && (
                <p>
                  {props.lang(
                    'O match de hospedagem não está configurado, entre em contato com o suporte'
                  )}
                  .
                </p>
              )}
              {props?.message === 'EXISTS_NO_MATCH_GROUPS' && (
                <p>
                  {props.lang(
                    'O seu perfil não está configurado para o match de hospedagem'
                  )}
                  .
                </p>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default CardError;
