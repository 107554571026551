import axios from "axios";

export const baseUrl = `${process.env.REACT_APP_URL_BASE}`;

export async function checkRoom({ eventID, size }) {
  const config = {
    method: "get",
    url: `${baseUrl}/events/${eventID}/rooms/size/${size}`,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createRoom(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/room`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function sendInviteConfirmation(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/roommateroom/inviteconfirmation`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { ...data },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getRoommatesRoom(eventId, roommateId) {
  const config = {
    method: "get",
    url: `${baseUrl}/roommateroom/event/${eventId}/roommate/${roommateId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getRoommatesRoomByRoomId(roomId) {
  const config = {
    method: "get",
    url: `${baseUrl}/roommateroom/room/${roomId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
export async function getMatchStatus(eventId, roommateId) {
  const config = {
    method: "get",
    url: `${baseUrl}/match/status/event/${eventId}/roommate/${roommateId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getParticipants({
  eventId,
  loginId,
  offset,
  limit,
  search = "",
}) {
  const config = {
    method: "get",
    url: `${baseUrl}/login/event/${eventId}/login/${loginId}?offset=${offset}&limit=${limit}&search=${search}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createRoommate(data) {
  const config = {
    method: "post",
    url: `${baseUrl}/roommateroom`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
