import axios from 'axios';

export const baseUrl = `${process.env.REACT_APP_URL_BASE}`;

export async function getMatchConfig(eventId, loginId) {
  const config = {
    method: 'get',
    url: `${baseUrl}/match/config/${eventId}/event/${loginId}/login`,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
