import { useContext, useState, useEffect } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { MatchContext } from '../../providers/match';
import CardRoomType from '../../components/RoomType';
import { Ienums } from '../../utils/interface';
import { useNavigate } from 'react-router-dom';
import { getRoommatesRoom } from '../../utils/services/roommates';
import { reset } from '../../store/users';
import CardError from '../../components/CardError';

export default function RoomSelect(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    idEvent,
    idParticipant,
    setNewContextRefresh,
    setRoomSize,
    setTypeOfRoom,
    t,
    matchConfig,
    i18n,
    errorConfig,
    errorConfigMsg,
  } = useContext(MatchContext);

  const [loadingGetInfo, setLoadingGetInfo] = useState(true);
  // quartos disponíveis
  const [availableRooms, setAvailableRooms] = useState(false);

  async function getParticipantInfo() {
    dispatch(reset([]));

    setLoadingGetInfo(true);

    await getRoommatesRoom(idEvent, idParticipant)
      .then((res) => {
        if (res.data.roomSize > 0) {
          dispatch(reset([]));
          setRoomSize(res?.data?.roomSize);
          setTypeOfRoom(res?.data?.roomSize);

          navigate({
            pathname: '/match',
            search: `?idEvent=${idEvent}&idPart=${idParticipant}&room=${res.data.roomSize}&idiom=pt-br`,
          });
          /* window.location.href =
            "/match" +
            `?idEvent=${idEvent}&idPart=${idParticipant}&room=${res.data.roomSize}&idiom=pt-br`; */
        } else {
          setLoadingGetInfo(false);
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Erro na requisição, atualize a página.');
      });
  }

  function getTextUnavailable() {
    const lang = i18n.language;
    let textUnavailable = '';
    if (lang === 'ptBr') {
      textUnavailable = matchConfig?.unavailableRoomsBr;
    }
    if (lang === 'en') {
      textUnavailable = matchConfig?.unavailableRoomsEn;
    }
    if (lang === 'sp') {
      textUnavailable = matchConfig?.unavailableRoomsSp;
    }

    return textUnavailable;
  }

  function getTextFooter() {
    const lang = i18n.language;
    let textFooter = '';
    if (lang === 'ptBr') {
      textFooter = matchConfig?.footerTextBr;
    }
    if (lang === 'en') {
      textFooter = matchConfig?.footerTextEn;
    }
    if (lang === 'sp') {
      textFooter = matchConfig?.footerTextSp;
    }

    return textFooter;
  }

  useEffect(() => {
    setNewContextRefresh();
    if (idEvent) getParticipantInfo();
  }, [idEvent]);
  // console.log(!availableRooms && getTextUnavailable() !== '');

  if (errorConfig) {
    return <CardError message={errorConfigMsg} lang={t} />;
  }

  if (loadingGetInfo)
    return (
      <div
        style={{
          width: '100%',
          minHeight: '100vh',
          backgroundColor: `${matchConfig.colorBackground}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    );
  else
    return (
      <div
        className="container-main"
        style={{
          // width: '100%',
          // minHeight: '100vh',
          backgroundColor: `${matchConfig.colorBackground}`,
          // display: 'flex',
          // justifyContent: 'center',
          // alignItems: 'center',
        }}
      >
        <div className="room-container-main">
          <Card className="room-card-main">
            <CardBody className="room-card-body">
              <CardTitle className="room-card-title">
                {t('Selecione a hospedagem')}
                <hr />
              </CardTitle>
              <div className="room-container-items">
                {!availableRooms
                  ? getTextUnavailable() || t('Quartos Indisponíveis')
                  : null}
                {idEvent && (
                  <>
                    <CardRoomType
                      labelText={t('Quarto duplo')}
                      roomSize={2}
                      id={idEvent}
                      part={idParticipant}
                      setAvailableRooms={setAvailableRooms}
                    />
                    <CardRoomType
                      labelText={t('Quarto triplo')}
                      roomSize={3}
                      id={idEvent}
                      part={idParticipant}
                      setAvailableRooms={setAvailableRooms}
                    />
                    <CardRoomType
                      labelText={t('Quarto quádruplo')}
                      roomSize={4}
                      id={idEvent}
                      part={idParticipant}
                      setAvailableRooms={setAvailableRooms}
                    />
                  </>
                )}
              </div>
            </CardBody>
            {getTextFooter() && (
              <CardFooter className="footer-text">
                <Row>
                  <Col>
                    <p>{getTextFooter()}</p>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </div>
      </div>
    );
}
