/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
// import { Row } from "reactstrap";

const Pagination = ({ data: foundEvents, search, currentIndex, setCurrentIndex, countData, maxItemPerPage, maxPageButtonsToShow, setMaxItemPerPage }) => {
  const allPages = [];
  const distanceCheckNext = Math.floor(maxPageButtonsToShow / 2);
  const distanceCheckPrevious = Math.ceil(maxPageButtonsToShow / 2);

  for (let page = 1; page <= Math.ceil(countData / maxItemPerPage); page += 1) {
    allPages.push(page);
  }

  const lastPage = allPages.length;

  const [itemsPerPageDrop, setItemsPerPageDrop] = useState(10);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [firstButtonIndex, setFirstButtonIndex] = useState(0);
  const [lastButtonIndex, setLastButtonIndex] = useState(maxPageButtonsToShow);

  // lista com os botões que vão aparecer na tela
  const showPagesButtons = allPages.slice(firstButtonIndex, lastButtonIndex);


  useEffect(() => {
    if (currentIndex > lastPage) {
      setCurrentIndex(lastPage)
      goToFirstPage()
    };
  }, [lastPage, currentIndex, setCurrentIndex]);

  const handlePageNumberClick = (pageNumber) => {
    if (pageNumber === currentIndex) return;

    // se estiver subindo no número de páginas
    if (pageNumber > currentIndex) {

      // caso o numero da pagina seja menor do que o meio da paginação não muda os botões
      if (pageNumber <= distanceCheckPrevious) {
        setCurrentIndex(pageNumber);
        return;
      }
      // caso não passe da quantidade de paginas total
      else if (pageNumber + distanceCheckNext < lastPage) {
        setLastButtonIndex(pageNumber + distanceCheckNext);
        setFirstButtonIndex(pageNumber - distanceCheckPrevious);
      } else {
        setLastButtonIndex(lastPage);
        setFirstButtonIndex(lastPage - maxPageButtonsToShow);
      }
    }

    // se estiver voltando no número de paginas
    if (pageNumber < currentIndex) {
      const firstPage = 0;

      if (pageNumber <= lastPage && pageNumber >= lastPage - distanceCheckNext) {
        setCurrentIndex(pageNumber);
        return;
      } 
      // caso voltar não ultra
      if (pageNumber - distanceCheckNext > firstPage) {
        setLastButtonIndex(pageNumber + distanceCheckNext);
        setFirstButtonIndex(pageNumber - distanceCheckPrevious);
      }
      else {
        setLastButtonIndex(maxPageButtonsToShow);
        setFirstButtonIndex(firstPage);
      }
    }

    setCurrentIndex(pageNumber);
  }

  const handlePreviousPage = (currentPage) => {
    const firstButton = 0;
    const firstPage = 1;

    if (currentPage === firstPage) return;

    // caso não passe da primeira pagina
    if (currentPage - distanceCheckPrevious === firstButtonIndex && firstButtonIndex !== firstButton) {
      setLastButtonIndex(lastButtonIndex - 1);
      setFirstButtonIndex(firstButtonIndex - 1);
    }

    setCurrentIndex(currentPage - 1);
  }

  const handleNextPage = (currentPage) => {

    if (currentPage === lastPage) return;

    // caso não passe da ultima pagina
    if (currentPage + distanceCheckNext === lastButtonIndex && lastButtonIndex !== lastPage) {
      setLastButtonIndex(lastButtonIndex + 1);
      setFirstButtonIndex(firstButtonIndex + 1);
    }

    setCurrentIndex(currentPage + 1);
  }

  const goToFirstPage = () => {
    const firstPage = 0;
    setLastButtonIndex(maxPageButtonsToShow);
    setFirstButtonIndex(firstPage);
    setCurrentIndex(1);
  }

  const goToLastPage = () => {
    setLastButtonIndex(lastPage);
    setFirstButtonIndex(lastPage - maxPageButtonsToShow);
    setCurrentIndex(lastPage);
  }

  const handleChange = (e) => {
    setItemsPerPageDrop(Number(e.target.value));
    setMaxItemPerPage(Number(e.target.value));
    goToFirstPage();
  }

  return (
    <Row style={{ display: "flex", justifyContent: "flex-end" }}>
      <nav
        aria-label="Page navigation example"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <ul className="pagination">
          <li>
            <Dropdown 
              toggle={() => { setDropdownOpen(!dropdownOpen) }}
              isOpen={dropdownOpen}
              style={{ marginRight: "5px", backgroundColor: '#556ee6' }}
              className="bg-primary"
              caret="true"
            >
              <DropdownToggle
                caret
                style={{ backgroundColor: '#556ee6' }}
              >
                {itemsPerPageDrop} Itens por pagina
              </DropdownToggle>
              <DropdownMenu >
                <DropdownItem onClick={(e) => handleChange(e)} value="10">10</DropdownItem>
                <DropdownItem onClick={(e) => handleChange(e)} value="25">25</DropdownItem>
                <DropdownItem onClick={(e) => handleChange(e)} value="50">50</DropdownItem>
                <DropdownItem onClick={(e) => handleChange(e)} value="100">100</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
          <li
            className={
              currentIndex === 1 && countData !== 0
                ? "page-item disabled"
                : "page-item"
            }
            onClick={() => { goToFirstPage() }}
          >
            <a
              className="page-link"
            >
              <i className="fas fa-angles-left"></i>
            </a>
          </li>
          <li
            className={
              currentIndex === 1 && countData !== 0
                ? "page-item disabled"
                : "page-item"
            }
            onClick={() => { handlePreviousPage(currentIndex) }}
          >
            <a
              className="page-link"
            >
              <i className="fas fa-arrow-left"></i>
            </a>
          </li>
          {foundEvents &&
            showPagesButtons.map((pageNumber) => (
              <li
                className={
                  currentIndex === pageNumber ? "page-item active" : "page-item"
                }
                key={pageNumber}
              >
                <a
                  className="page-link"
                  key={pageNumber}
                  onClick={() => {
                    handlePageNumberClick(pageNumber);
                  }}
                >
                  {pageNumber}
                </a>
              </li>
            ))}
          <li
            className={
              allPages.length !== currentIndex && countData !== 0
                ? "page-item"
                : "page-item disabled"
            }
            onClick={() => handleNextPage(currentIndex)}
          >
            <a className="page-link">
              <i className="fas fa-arrow-right"></i>
            </a>
          </li>
          <li
            className={
              allPages.length !== currentIndex && countData !== 0
                ? "page-item"
                : "page-item disabled"
            }
            onClick={() => goToLastPage()}
          >
            <a className="page-link">
              <i className="fas fa-angles-right"></i>
            </a>
          </li>
        </ul>
      </nav>
      <div className="pagination-infos" style={{ display: 'flex', justifyContent: 'space-between', width: '265px' }}>
        <a style={{ width: "auto" }}>Total de items: {countData}</a>
        <a style={{ width: "auto" }}>Total de paginas: {allPages.length}</a>
      </div>
    </Row>
  );
};

export default Pagination;
