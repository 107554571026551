import { createSlice } from "@reduxjs/toolkit";

function resetOrderInDelete({ allUsers, idDelete }) {
  var newArray = allUsers.filter((user) => user.id !== idDelete);

  var formatOrder = newArray.map((e, i) => {
    if (e)
      return {
        ...e,
        order: i + 1,
      };
  });

  return formatOrder;
}

// Slice
const userSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    status: "chooser_pending",
  },
  reducers: {
    getUser: (state, action) => {
      state.users = action.payload;
    },
    createUser: (state, action) => {
      state.users.push(action.payload);
    },
    deleteUser: (state, action) => {
      // state.users = state.users.filter((user) => user.id !== action.payload.id);
      state.users = resetOrderInDelete({
        allUsers: state.users,
        idDelete: action.payload.id,
      });
    },
    reset: (state, action) => {
      state.users = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});
export const { createUser, deleteUser, getUser, reset, setStatus } =
  userSlice.actions;
export default userSlice.reducer;
