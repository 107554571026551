import { BrowserRouter, Routes, Route } from "react-router-dom";
//styles
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/theme.scss";
import "./App.css"
//components
import Match from "./pages/match";
import RoomSelect from "./pages/select";
// import Report from "./pages/report";
import Participants from "./pages/participant";
import MatchProvider from "./providers/match";
import ParticipantProvider from "./providers/participant";

function App() {
  return (
    <BrowserRouter>
      <MatchProvider>
        <Routes>
          <Route path="/select" element={<RoomSelect />} />
          <Route path="/match" element={<Match />} />
          <Route
            path="/participants"
            element={
              <ParticipantProvider>
                <Participants />
              </ParticipantProvider>
            }
          />
          {/* <Route path="/report" element={<Report />} /> */}
        </Routes>
      </MatchProvider>
    </BrowserRouter>
  );
}

export default App;
