import React, { useEffect, useState } from "react";
import Room from "../rooms";
import { MatchContext } from "../../providers/match";
import { getRoommatesRoom } from "../../utils/services/roommates";
import { Ienums } from "../../utils/interface";
import { useDispatch, useSelector } from "react-redux";
import { createUser, reset } from "../../store/users";

export default function Match() {
  const { idParticipant, idEvent, setNewContextRefresh, matchConfig } =
    React.useContext(MatchContext);
  const { users } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setNewContextRefresh();
  }, []);
  useEffect(() => {
    getStatus(idEvent, idParticipant);
  }, [idParticipant, idEvent]);

  function getStatus(eventId, roommateId) {
    getRoommatesRoom(eventId, roommateId).then((res) => {
      if (res.message === "ROOMMATE_ROOMS_NOT_FOUND" && users.length === 0) {
        dispatch(reset([]));
        dispatch(
          createUser({
            ...res.data,
            type: Ienums.CHOOSER,
            status: Ienums.COMPLETED,
            order: users.length + 1,
          })
        );
      }
      if (
        res.message === "ROOMMATE_ROOMS_NOT_FOUND" &&
        users.filter((e) => e.status === Ienums.PENDING_ACCEPT).length > 0
      ) {
        dispatch(reset([]));
        dispatch(
          createUser({
            ...res.data,
            type: Ienums.CHOOSER,
            status: Ienums.COMPLETED,
            order: users.length + 1,
          })
        );
      }

      if (res.message === Ienums.CHOOSER) {
        dispatch(reset([]));
        res.data.dataRommateRoom.map((el) => {
          let { roommateId, login, status, order, chooser } = el;
          dispatch(
            createUser({
              id: roommateId,
              name: login.name,
              email: login.email,
              status: status,
              order: order,
              type: chooser ? Ienums.CHOOSER : Ienums.CHOOSEN,
            })
          );
        });
      }

      if (res.message === Ienums.CHOOSEN) {
        dispatch(reset([]));
        res.data.dataRommateRoom.map((el) => {
          let { roommateId, login, status, order, chooser } = el;
          dispatch(
            createUser({
              id: roommateId,
              name: login.name,
              email: login.email,
              status: status,
              order: order,
              type: chooser ? Ienums.CHOOSER : Ienums.CHOOSEN,
            })
          );
        });
      }
      setLoading(false);
    });
  }
  return (
    <div
      className="container-main"
      style={{ backgroundColor: matchConfig?.colorBackground }}
    >
      {users.length > 0 && !loading && <Room />}
    </div>
  );
}
