import React, { useState, useEffect } from 'react'
import { useParticipants } from "../../hooks/useParticipants"
import { useTranslation } from "react-i18next";

export const ParticipantContext = React.createContext({})

function ParticipantProvider(props) {
  const [idParticipant, setIdParticipant] = useState();
  const [idEvent, setIdEvent] = useState('');
  const [roomSize, setRoomSize] = useState('');
  const [roomId, setRoomId] = useState('');

  const { t, i18n } = useTranslation()

  function getParamsUrl() {
    const params = new URLSearchParams(window.location.search);
    setIdParticipant(params.get('idPart'))
    setIdEvent(params.get('idEvent'))
    setIdiom(params.get('idiom'))
    setRoomSize(params.get('room'))
    setRoomId(params.get('roomId'))
  }

  function setIdiom(idiom) {
    const lang = (idiom === "pt-br" || idiom === "ptBr" || idiom?.toLowerCase() === "pt")
      ? "ptBr" : (idiom === "sp" || idiom === "es")
        ? "sp" : "en"
    i18n.changeLanguage(lang)
  }
  useEffect(() => {
    getParamsUrl()
  }, []);

  return (
    <ParticipantContext.Provider value={{ t, idParticipant, idEvent, roomSize, roomId, i18n }}>
      {props.children}
    </ParticipantContext.Provider>
  )
}

export default ParticipantProvider
